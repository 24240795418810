import en from '../locales/en';
import es from '../locales/es';
import de from '../locales/de';
import fr from '../locales/fr';
import ja from '../locales/ja';
import ko from '../locales/ko';
import pt from '../locales/pt';
import ru from '../locales/ru';
import it from '../locales/it';
import et from '../locales/et';
import lt from '../locales/lt';
import lv from '../locales/lv';
import nl from '../locales/nl';
import hi from '../locales/hi';
import zh from '../locales/zh';

// This is the list of languages your application supports
export const supportedLngs = [
  'en',
  'hi',
  'de',
  'es',
  'nl',
  'it',
  'fr',
  'ja',
  'ko',
  'pt',
  'ru',
  'zh',
  'lv',
  'lt',
  'et',
];

// This is the language you want to use in case
// if the user language is not in the supportedLngs
export const fallbackLng = 'en';

// The default namespace of i18next is "translation", but you can customize it
// here
export const defaultNS = 'translation';

export const resources = {
  en: { translation: en },
  hi: { translation: hi },
  de: { translation: de },
  es: { translation: es },
  nl: { translation: nl },
  it: { translation: it },
  fr: { translation: fr },
  ja: { translation: ja },
  ko: { translation: ko },
  pt: { translation: pt },
  ru: { translation: ru },
  zh: { translation: zh },
  lv: { translation: lv },
  lt: { translation: lt },
  et: { translation: et },
};
